<script setup>
import AdviceCarousel from "./AdviceCarousel.vue";
import simpleQuiz from "../data/simple.json";

const gatherFeedback = (theme) => {
  return theme.items.map(item => item.feedback)
}

</script>

<template>
  <AdviceCarousel v-for="theme in simpleQuiz"
                  :img="`/static/vendors/img/0${theme.id}.jpg`"
                  :title="theme.name"
                  :advices="gatherFeedback(theme)"
  />
</template>

<style scoped lang="scss">

</style>